import React from 'react';
import "./WhyGaleSection.css"
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function WhyGaleSection() {
  return (
      <Container className={"why-gale-section d-flex align-items-center anchor"}
                 id={"why-gale"} fluid>
        <Row md={12}>
          <Col md={{span: 6}} sm={12} xs={12}
               className={'why-gale-image my-4 py-4'}>
            <Image className={'fill'} src="/img/why_gale_image.svg" fluid/>
          </Col>
          <Col xl={{span: 5, offset: 0}} lg={{span: 5, offset: 0}}
               md={{span: 5, offset: 0}} sm={{span: 10, offset: 1}}
               xs={{span: 10, offset: 1}}
               className={'why-gale-section-content'}>
            <Row>
              <Col md={12}>
                <p className={'why-gale-section-header'}><strong>3/10</strong> Transformation
                  Efforts <strong>Succeed.</strong>
                </p>
              </Col>
              <Col md={12}>
                <p className={'why-gale-section-text why-gale-section-text__responsive'}
                   style={{textAlign: "left", paddingLeft: 0}}>
                  GALE is on a mission to improve that number by enabling Organisations in their journeys of becoming Digital-first, address the 3 key challenges of:
                </p>
              </Col>
              {/*<Col md={12}>*/}
              {/*  <p className={'why-gale-section-header'}>Gale <strong>Enables</strong>*/}
              {/*  </p>*/}
              {/*</Col>*/}

              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Reducing the Strategy-execution gaps</p>
              </Col>
              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Bridging the Digital Talent gaps</p>
              </Col>
              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Measuring the Value gaps</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
  )
}
