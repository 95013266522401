import React from 'react';
import "./SuccessPage.css"
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import {Button} from "react-bootstrap";

export default class SuccessPage extends React.Component {

  componentDidMount() {
    const { history } = this.props;

    window.addEventListener("popstate", () => {
      history.go(1);
    });
  }

  render() {
    return (
        <Container className={'d-flex success-container'
        + ' flex-column'} fluid>

          <Row>
            <Col className={'d-flex'} md={6} xs={12}>
              <Row>
                <Col xs={12}>
                  <p className={'welcome'}>
                    <strong>Welcome </strong> on board</p>
                </Col>
                <Col xs={12}>
                  <p className={'message'}>Thank you for signing up onto GALE as an organisation.
                    You will be receiving a confirmation
                    e-mail
                    from us shortly. Please click the link in the email to
                    login to
                    your account.</p>
                </Col>
                <Col xs={12}>
                  <Button className={'okay-button'} variant={'outline-secondary'} style={{margin:'0px'}} href={`${window.location.origin}`}>Okay</Button>
                </Col>
              </Row>
            </Col>
            <Col className={'mx-auto'} md={6} xs={12}>
              <Image className={'fill sign-up-image'} src={'/img/signup.svg'} fluid/>
            </Col>
          </Row>
        </Container>
    )
  }
}
