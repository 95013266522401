import React from "react";
import {Button, Image, Container, Row, Col} from "react-bootstrap";
import "./LandingSection.css";
// import {Link} from "react-router-dom";

function LandingSection(props) {
  return (
      <Container className={"landing-section d-flex align-items-center"}
                 id={"landing-section"} fluid>
        <Row md={12}>
          <Col md={{span: 5, offset: 1, order:1}} sm={{span: 10, offset: 1, order:2}}
               xs={{span: 10, offset: 1, order:2}} className={'landing-section-content'}>
            <Row>
              <Col md={12} className={'landing-section-header-container'}>
                <p className={"landing-section-header"}>Scale Digital <strong><br/>
                  <span className={'the-right-way'}>the right way</span></strong></p>
              </Col>
              <Col md={12}>
                <p className={"landing-section-text"
                + " landing-section-text__responsive"}>
                  Bring your <b>Digital Strategy</b> to Life with Insights that help you drive <b>value that matters.</b></p>
              </Col>
              <Col className={'pt-2'}>
                <Button className={'demo-button'} style={{marginLeft: 0}} variant="light"
                        href="#contact-us"><p
                    className={' landing-section-text__responsive'
                    + ' mb-0'} onClick={props.setSubject()}>Book a demo</p></Button>
                {/*<Link to={"/register"}> <Button className={'sign-up-button'} style={{marginLeft: 0}} variant="outline-primary"><p*/}
                {/*    className={'sign-up-button'*/}
                {/*    + ' landing-section-text__responsive mb-0'} >Sign up</p></Button></Link>*/}
              </Col>
            </Row>
          </Col>
          <Col md={{span: 6, order: 2}} sm={{span:12, order:1}} xs={{span:12, order:1}}
               className={'landing-section-image-container my-4 py-4'}>
            <Image className={'landing-section-image fill img-responsive'} src="/img/landing_section_image.svg"
                   fluid/>
          </Col>
        </Row>
      </Container>
  );
}

export default LandingSection;
