import FieldData from '../fixtures/FieldData';

export const validEmail = email => {
  return (
      !isEmpty(email) &&
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
      !inValidDomain(email)
  );
};


export const isEmpty = data => {
  return data ? data.trim().length === 0 : true;
};

export const inValidDomain = domain => {
  const invalidDomains = FieldData.InvalidDomain;
  const domainName = domain.split('@')[1].split('.')[0];
  return invalidDomains.includes(domainName.toLowerCase());
};

export const validWebAddress = url => {
  if (url.trim().length === 0) {
    return true;
  }
  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return urlRegex.test(url);
};

export const validPhoneNumber = number => {
  if (number.trim().length === 0) {
    return true;
  }
  return number.length >= 10 && number.length <= 14 && number.match(/^[0-9]+$/);
};

export const filledRequired = (formData) => {
  if (
      formData.companyName &&
      formData.industry &&
      formData.companyType &&
      formData.numberOfEmployeeRange &&
      formData.companyAddress &&
      formData.siteName &&
      formData.siteType &&
      formData.siteCity &&
      formData.siteCountry &&
      formData.siteAdminName &&
      formData.siteAdminEmail
  ) {
    return true;
  }
  return false;
};


export const createFormData = (
    object,
    form,
    namespace) => {
  const formData = form || new FormData();
  for (const property in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.hasOwnProperty(property) || !object[property]) {
      continue;
    }
    const formKey = namespace ? `${namespace}.${property}` : property;
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File)
    ) {
      createFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
};


export const validLogo = (file) => {
    return file? file.size / 1024 / 1024 < 2 : true ;
};
