export const featuresInfo = [
  {
    name: "Dashboard",
    description:
        "The Navigator for the Leadership, Leads and Individuals with Insights Enabling Alignment to a Common Purpose.",
  },
  {
    name: "Feedback",
    description:
        "Engage & Measure Talent Performance with Comprehensive 360, Project and Stakeholder feedback.",
  },
  {
    name: "Goals",
    description:
        "Define OKRs. Manage Cross-functional Initiatives. Track Tactical Activity Progress. And Measure Value Outcomes.",
  },
  {
    name: "Learning",
    description:
        "Define Learning plans, Build Relevant Skills and Track Improvements, in Alignment with Org Goals.",
  },
  {
    name: "Admin Settings",
    description:
        "Set up your Org on GALE with Units, People, Roles and Rights in a Structure that Fits your Use case.",
  },
];

export const gifs = [
  'https://storage.googleapis.com/gale_website/dashboard.png',
  'https://storage.googleapis.com/gale_website/feedback.png',
  'https://storage.googleapis.com/gale_website/goals.png',
  'https://storage.googleapis.com/gale_website/learning.png',
  'https://storage.googleapis.com/gale_website/administration.png',
]



