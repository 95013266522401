import React from 'react';
import "./ContactUs.css"
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup'
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {validEmail, validPhoneNumber} from "../validation";

export default class ContactUs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mandatoryFieldValidated: true,
      message: '',
    }
  }


  content = (name) => {
    return (
        <div className={'toast-message'}>
          <h6 className={'pt-3 pl-4'} style={{textAlign: 'left'}}><strong>We
            have received your request</strong></h6>
          <p className={'pl-4'} style={{textAlign: 'left'}}>Hello {name}!
            Thank you for reaching out.
            Our team will get in touch with you shortly.</p>
        </div>

    )
  }



  toastMessage = (name) => {
    toast.info(this.content(name), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  toastError = () => {
    toast.error('Oops! Something went wrong!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  onClick = () => {
    const {formData} = this.props;
    let validation = true;
    let message = '';
    if (formData.name.length === 0 ||
        formData.email.length === 0 ||
        formData.subject.length === 0) {
      validation = false;
      message = 'Please input all the mandatory fields!';
    }
    else if(!validEmail(formData.email)){
      validation = false;
      message = 'Please input a valid email address';
    }
    else if(!validPhoneNumber(formData.contactNumber)){
      validation = false;
      message = 'Please input a valid phone number';
    }

    this.setState({
      mandatoryFieldValidated: validation,
      message: message
    })

    if (validation) {
      axios.post(
          'http://www.greyamp.gale.today/api/registration/connect', formData
      ).then(() => {
        this.toastMessage(formData.name)
      })
      .catch((error) => {
        // this.toastError()
        this.toastMessage(formData.name)
      });

    }
  }

  render() {
    const {formData} = this.props;

    let errorComponent;

    if (!this.state.mandatoryFieldValidated) {
      errorComponent =
          <Col className={'alert alert-danger mx-auto'} xs={'auto'}>
            {this.state.message}
          </Col>
    }

    return (
        <Container className={"contact-us-section d-flex flex-column anchor"}
                   id={"contact-us"} fluid>
          <Row sm={12} xs={12} md={12}>
            <Col sm={12} xs={12} md={{span: 12}}
                 className={'justify-content-center'}>
              <p style={{fontSize: 50}}><strong>Contact Us</strong></p>
            </Col>
            <Col sm={12} xs={12} md={{span: 12}}
                 className={'justify-content-center'}>
              Or email us at &nbsp;&nbsp;<a href="mailto:galedev@greyamp.com">galedev@greyamp.com</a>
            </Col>
            <Col sm={12} xs={12} md={{span: 5, offset: 1}}
                 className={'form justify-content-center mt-5'}>
              <Form>
                <InputGroup className="mb-3">
                  <Form.Control
                      onChange={this.props.onChange()}
                      value={formData.name}
                      name={'name'}
                      type="text"
                      placeholder="Name*"
                      aria-label="Name"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Control
                      onChange={this.props.onChange()}
                      value={formData.email}
                      name={'email'}
                      type="email"
                      placeholder="Email*"
                      aria-label="Email"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Control
                      onChange={this.props.onChange()}
                      value={formData.contactNumber}
                      name={'contactNumber'}
                      placeholder="Contact Number"
                      aria-label="Name"
                  />
                </InputGroup>
              </Form>
            </Col>
            <Col sm={12} xs={12} md={5}
                 className={'justify-content-center mt-5'}>
              <Form>
                <InputGroup className="mb-3">
                  <Form.Control
                      onChange={this.props.onChange()}
                      value={formData.subject}
                      name={'subject'}
                      placeholder="Subject*"
                      aria-label="Subject"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Control
                      onChange={this.props.onChange()}
                      value={formData.query}
                      className={'text-area'}
                      name={'query'}
                      placeholder="Tell us how we can help you :)"
                      as="textarea"
                      aria-label="With textarea"
                      rows="3"/>
                </InputGroup>
              </Form>
            </Col>
            {errorComponent}
            <Col xs={12} md={12} className={'justify-content-center pb-3'}>
              <Button variant="light" onClick={this.onClick}>Send
                Message</Button>
            </Col>
          </Row>
          <ToastContainer/>
        </Container>

    )
  }
}
