import React, {Component} from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import {featuresInfo, gifs} from './features.info';
import './FeaturesSection.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Button} from "react-bootstrap";

export default class FeaturesSection extends Component {
  state = {
    activeSlide: 0
  }

  render() {
    const settings = {
      centerMode: true,
      infinite: true,
      dots: true,
      initialSlide: 0,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 500,
      pauseOnDotsHover: true,
      border: "none",
      dotsClass: "slick-dots show-dots",
      afterChange: current => this.setState({activeSlide: current}),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

    };

    const content = featuresInfo.map(
        ({name, description, index}) => (
            <Col key={name} xs={12} sm={12} md={12}>
              <Card key={index} className={'feature-card my-5 mx-3'}>
                <Card.Body>
                  <Card.Title style={{textAlign: 'left'}}
                              className={'feature-title'}>{name}</Card.Title>
                  <Card.Text
                      style={{textAlign: 'left'}}
                      className={'feature-text'}>{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
        )
    )

    return (

        <Container className={'features-section mt-5 anchor'} id={'features'} fluid>
          <Slider className={'slider-features mx-auto'} {...settings}>
            {content}
          </Slider>
          <Row>
            <Col className={'mx-auto'} xs={10} sm={10} md={6}>
              <Image className={'pt-5 feature-image'}
                     src={gifs[this.state.activeSlide]}
                     fluid/>
            </Col>
          </Row>
          <Row>
            <Col className={'d-flex justify-content-center team-section-header'
            + ' mx-auto my-5'}>
              <Button
                  variant="secondary"
                  href={'#contact-us'}
              onClick={this.props.setSubject()}
              >
                Get a detailed demo
              </Button>
            </Col>
          </Row>
        </Container>
    );
  }
}
