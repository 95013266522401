import React from 'react';
import "./RegistrationForm.css"
import Navbar from "react-bootstrap/Navbar";
import Form from 'react-bootstrap/Form'
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import FieldData from '../../fixtures/FieldData'
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../FooterSection/Footer";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { Prompt, Route, Switch } from "react-router-dom";
import SuccessPage from "../SuccessPage/SuccessPage";
import {
  createFormData,
  filledRequired,
  validEmail, validLogo,
  validPhoneNumber,
  validWebAddress
} from "../validation";

export default class RegistrationForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      registration: {
        companyName: '',
        industry: '',
        companyType: '',
        numberOfEmployeeRange: '',
        companyAddress: '',
        website: '',
        contact: {
          countryCode: '',
          contactNumber: ''
        },
        siteName: '',
        siteType: '',
        siteCity: '',
        siteCountry: '',
        siteAdminName: '',
        siteAdminEmail: '',
        logo: null
      },
      mandatoryFieldValidated: true,
      message: '',
      countries: [],
      cities: [],
      places: [],
      countryCodes: []
    }
  }
  removeDublicateValues(data) {
    return data && data.filter((value, index) => data.indexOf(value) === index);
  }

  componentDidMount() {
    axios.get('http://www.greyamp.gale.today/api/places').then((response) => {

      const countries = response.data.map(eachData => eachData.name);
      const countryCodes = response.data.map(eachData => eachData.code);
      this.setState({ countries: this.removeDublicateValues(countries), places: response.data, countryCodes: this.removeDublicateValues(countryCodes) });
    }, () => {
      console.log("error while feching")
    })
  }

  getCities(places, selectedCountry) {
    return places.find(eachData => {
      if (eachData.name === selectedCountry) {
        return eachData.cities;
      }
    });
  };

  onChange = (event) => {
    const { registration } = this.state;
    const name = event.target.name;
    if (name === 'contactNumber' || name === 'countryCode') {
      registration.contact[event.target.name] = event.target.value;
    } else if (name === 'siteCountry') {
      registration[event.target.name] = event.target.value;
      const cities = this.getCities(this.state.places, event.target.value);
      this.setState({ cities: this.removeDublicateValues(cities?.cities) });
      if (cities?.cities.indexOf(registration.siteCity) === -1) {
        registration['siteCity'] = '';
      }
    }
    else {
      registration[event.target.name] = event.target.value;
    }

    this.setState({ registration });
  };

  textInput = (name, placeholder, xsSize, lgSize) => {
    return <Form.Group className={'px-3'} as={Col} xs={xsSize} lg={lgSize}
      controlId="formGridEmail">
      <Form.Control
        type='text'
        name={name}
        placeholder={placeholder}
        onChange={this.onChange}
        value={this.state.registration[name]}
      />
    </Form.Group>
  }

  dropdownInput = (name, placeholder, options, xsSize, lgSize) => {
    return <Form.Group className={'px-3'} as={Col} xs={xsSize} lg={lgSize}>
      <Form.Control
        as="select"
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={this.onChange}
        value={this.state.registration[name]}
        required
        custom>
        <option className={'placeholder'} value={""} selected
          disabled> {placeholder}
        </option>
        {options && options.map((option) => this.renderOption(option))}
      </Form.Control>
    </Form.Group>
  }

  textAreaInput = (name, placeholder, xsSize, lgSize) => {
    return <Form.Group className={'px-3'} as={Col} xs={xsSize} lg={lgSize}>
      <Form.Control
        className={'text-area'}
        name={name}
        onChange={this.onChange}
        value={name === 'countryCode' || name === 'contactNumber'
          ? this.state.registration.contact[name]
          : this.state.registration[name]}
        placeholder={placeholder}
        as="textarea"
        aria-label="With textarea"
        rows="3" />
    </Form.Group>
  }

  renderOption = (option) => {
    const value = option.value ? option.value : option
    return <option key={value}
      value={value}>{value}</option>
  }

  onFileChange = (event) => {
    const { registration } = this.state
    registration.logo = event.target.files[0];

    this.setState({ registration });
  }



  submitRegistration = () => {
    const { registration } = this.state;
    let validation = true;
    let message = '';
    if (!filledRequired(registration)) {
      validation = false;
      message = 'Please input all the mandatory fields!';
    } else if (!validWebAddress(registration.website)) {
      validation = false;
      message = 'Please input a valid website address';
    } else if (!validPhoneNumber(registration.contact.contactNumber)) {
      validation = false;
      message = 'Please input a valid phone number';
    } else if (!validLogo(registration.logo)) {
      validation = false;
      message = 'File Size exceeds 2MB';
    } else if (!validEmail(registration.siteAdminEmail)) {
      validation = false;
      message = 'Please input a valid email address';
    }

    this.setState({
      mandatoryFieldValidated: validation,
      message: message
    })

    if (validation) {

      axios.post('http://www.greyamp.gale.today/api/registration/organization',
        createFormData(this.state.registration),
        { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response);
          this.toastMessage()
          setTimeout(
            () => this.props.history.push('/register/success')
            , 3000);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.toastError(error.response.data.message)
        });
    }

  }

  toastMessage = () => {
    toast.info('Your registration was successful. We have sent an email with'
      + ' the confirmation!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  toastError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const { registration } = this.state;

    let errorComponent;

    if (!this.state.mandatoryFieldValidated) {
      errorComponent =
        <Form.Group as={Col} className={'alert alert-danger px-5 mx-auto'
          + ' justify-content-center'} xs={8}>
          {this.state.message}
        </Form.Group>
    }

    const home = `${window.location.origin}`;
    return (
      <>
        <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark"
          variant="dark" style={{ zIndex: 3 }}>
          <Navbar.Brand href={home}><img
            src={'/img/gale_logo.svg'} alt={"Greyamp Logo"} /></Navbar.Brand>
        </Navbar>
        <Switch>
          <Route exact path={'/register'}>
            <Container className={'d-flex registration-form-container'
              + ' flex-column'} fluid>

              <Row className={'heading'}>
                <Col xs={12}>
                  <p className={'main-header'} style={{ color: 'white' }}>Create
                      your <strong>GALE account</strong></p>
                </Col>
              </Row>

              <Row className={'heading'}>
                <Col xs={12} className={'mx-auto'}>
                  <p style={{ fontSize: '1.2rem', color: 'white' }}><strong>Tell
                  us
                  about
                      your organisation</strong></p>
                </Col>
              </Row>

              <Form>
                <Prompt
                  message={(location, action) => {
                    if (action === 'POP' && location.pathname
                      !== '/register/success') {
                      return `Are you sure you want to go back?`
                    }
                  }
                  }
                />
                <Form.Row>
                  <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Row>
                      {this.textInput('companyName', 'Company Name* (as'
                        + ' registered)', 12, 6)}
                      {this.dropdownInput('industry', 'Industry*',
                        FieldData.IndustryType,
                        12, 6)}
                      {this.dropdownInput('companyType', 'Company Type*',
                        FieldData.CompanyType, 12, 6)}
                      {this.dropdownInput('numberOfEmployeeRange', 'Number of'
                        + ' Employees*',
                        FieldData.EmployeeCount, 12, 6)}
                      {this.textAreaInput('companyAddress',
                        'Company Address*',
                        12)}
                      {this.textInput('website', 'Website*', 12, 5)}
                      {this.dropdownInput('countryCode', '+',
                        this.state.countryCodes, 2, 2)}
                      {this.textInput('contactNumber', 'Contact number', 10,
                        5)}
                      <Form.Group className={'px-3'} as={Col} xs={12}>
                        <Form.File
                          id="custom-file"
                          custom>
                          <Form.File.Input onChange={this.onFileChange}
                            accept={"image/png, image/jpeg"} />
                          <Form.File.Label data-browse="Browse">
                            {registration.logo ? registration.logo.name
                              : 'Upload Company Logo'}
                          </Form.File.Label>
                        </Form.File>
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                  <Form.Group as={Col} xs={0} lg={{ offset: 1, span: 4 }}>
                    <Image className={'fill'} src={'/img/signup.svg'} fluid />
                  </Form.Group>
                </Form.Row>
              </Form>

              <Row className={'heading'}>
                <Col xs={12} className={'mx-auto'}>
                  <p style={{ fontSize: '1.2rem', color: 'white' }}><strong>Let's
                      setup your site</strong></p>
                </Col>
              </Row>

              <Form>
                <Form.Row>
                  <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Row>
                      {this.textInput('siteName', 'Site Name* (as'
                        + ' registered)', 12, 6)}
                      {this.dropdownInput('siteType', 'Site Type*',
                        FieldData.SiteType,
                        12, 6)}
                      {this.dropdownInput('siteCountry', 'Site Country*', this.state.countries, 12, 6)}
                      {this.dropdownInput('siteCity', this.state.registration.siteCountry ? 'Site City*' : 'Please Select Country to select city!', this.state.cities, 12, 6)}
                      {this.textInput('siteAdminName', 'Site Admin Name*', 12,
                        6)}
                      {this.textInput('siteAdminEmail', 'Site Admin Email*',
                        12, 6)}
                      {errorComponent}
                    </Form.Row>
                  </Form.Group>
                </Form.Row>
              </Form>

              <Button
                className={'register'}
                variant="light"
                onClick={this.submitRegistration}
              >
                Register
                </Button>
              <ToastContainer />
            </Container>
          </Route>
          <Route exact path={'/register/success'} component={SuccessPage} />
        </Switch>
        <Footer />
      </>
    )
  }
}
