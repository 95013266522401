import React from "react";
import {Card, Button} from "react-bootstrap";
import Slider from "react-slick";
import teamInfo from "./team.info";
import "./TeamSection.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TeamSection() {
  const greyampURL = "https://www.greyamp.com/";
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    pauseOnDotsHover: true,
    border: "none",
    dotsClass: "slick-dots show-dots",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gotoURL = (url) => {
    console.log("lol", url);
    window.open(url, "_blank");
  };
  const content = teamInfo.map(
      ({name, description, profilePicUrlRegular, profilePicUrlHovered, linkedInUrl},
          index) => (

          <Col key={index} xs={11}>
            <Card xs={12} className={'team-member-card mb-5'}>
              <Col className={'d-flex justify-content-center photo-container'}>
                <img
                    src={profilePicUrlRegular}
                    className={"team-member-photo text-center mx-auto"}
                    alt={'team_member_photo.png'}
                />
                <img
                    src={profilePicUrlHovered}
                    className={"team-member-photo team-member-photo__color"
                    + " text-center mx-auto"}
                    alt={'team_member_photo_color.png'}
                />
              </Col>
              <Card.Body>
                <Card.Title className={'team-member-name'}>
                  {name}
                </Card.Title>
                <Card.Text className={'team-member-description d-flex'
                }>{description}</Card.Text>
              </Card.Body>
              <Card.Footer>
                <img
                    style={{height: "20px"}}
                    src="/img/Linkedin_icon.svg"
                    className={"mx-auto"}
                    alt="linkedIn"
                    onClick={() => gotoURL(linkedInUrl)}
                />
              </Card.Footer>
            </Card>
          </Col>
      )
  );

  return (
      <Container id="team" className={"team-section anchor"} fluid>
        <Row>
          <Col xs={12} className={'d-flex justify-content-center'
          + ' team-section-header'
          + ' mx-auto'}>
            Meet the <strong>&nbsp;Team</strong>
          </Col>
          {/*<Col xs={12} className={'d-flex justify-content-center'*/}
          {/*+ ' team-section-text__responsive'*/}
          {/*+ ' mx-auto'}>*/}
          {/*  The artists who've nailed the science behind transformation*/}
          {/*  management*/}
          {/*</Col>*/}
        </Row>
        <Slider className={'slider-team mx-auto my-3 py-2'} {...settings}>
          {content}
        </Slider>

        <Row>
          <Col className={'d-flex justify-content-center team-section-header'
          + ' mx-auto my-3'}>
            <Button
                variant="outline-secondary"
                onClick={() => gotoURL(greyampURL)}>
              Get to know us better...
            </Button>
          </Col>
        </Row>
      </Container>
  );
}

export default TeamSection;
