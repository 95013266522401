const teamInfo = [
  {
    name: "Preethi",
    description:
    "An Agile enthusiast having had experience working with organisations & teams grappling with digital transformation initiatives",
    linkedInUrl: "https://www.linkedin.com/in/preethimadhu/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Preethi_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Preethi_Regular_Final.svg",
  },
  {
    name: "Madhu",
    description:
    "Hands-on sales leader & serial entrepreneur with diverse experience scaling businesses across various geographies",
    linkedInUrl: "https://www.linkedin.com/in/madhu-lakshmanan-12b5ba4/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Madhu_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Madhu_Regular_Final.svg",
  },
  {
    name: "Nidhi",
    description:
    "Result-oriented Fullstack developer with a knack for conceptualising and delivering elegant, user-friendly solutions",
    linkedInUrl: "https://www.linkedin.com/in/nidhi-mittal-6b576b12a/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Nidhi_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Nidhi_Regular_Final.svg",
  },
  {
    name: "Naveen S",
    description:
    "A Tech-enthusiast, and experienced DevOps Consultant with hands-on automation expertise",
    linkedInUrl: "https://www.linkedin.com/in/naveenshankareswaran/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Naveen_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Naveen_Regular_Final.svg",
  },
  {
    name: "Manish",
    description:
        "Consulting professional with experience in Strategy & Business Consulting across Govt., Insurance, and Technology sectors",
    linkedInUrl: "https://www.linkedin.com/in/manishghosal/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Manish_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Manish_Regular_Final.svg",
  },
  // {
  //   name: "Neha",
  //   description:
  //       "A talent & culture consultant, MBTI Practitioner with experience in building growth-oriented and high-performing teams",
  //   linkedInUrl: "https://www.linkedin.com/in/paridhikgupta/",
  //   profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Neha_Hovered_Final.svg",
  //   profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Neha_Regular_Final.svg",
  // },
//  {
//    name: "Preeti M",
//    description:
//        "Outcome oriented HR Leader, with experience in business partnering with teams in Hi-Tech Environment, hands on experience in building & managing talent in global, fast paced, matrixed environment & multiple industries",
//    linkedInUrl: "https://in.linkedin.com/in/preeti-madesh-700a2922",
//    profilePicUrlHovered: "https://i.postimg.cc/g2VCCJ1n/Untitled-1-01.png",
//    profilePicUrlRegular: "https://i.postimg.cc/0y1gXmWT/BW-1-03.png",
//  },
//  {
//    name: "Saksham",
//    description:
//        "A Designer with experience of User Experience, User Interface and Product Design across Sectors including Ed-Tech, Embedded Systems, Information Technology, Assistive Technology",
//    linkedInUrl: "https://in.linkedin.com/in/mahajansaksham",
//    profilePicUrlHovered: "https://i.postimg.cc/xdgWdZfQ/Untitled-1-02.png",
//    profilePicUrlRegular: "https://i.postimg.cc/MGzCqw8t/BW-1-04.png",
//  },
  {
    name: "Santosh",
    description:
        "A fullstack developer with an affinity for backend development",
    linkedInUrl: "https://www.linkedin.com/in/santoshbachar/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Santosh_Hovered_Final.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Santosh_Regular_Final.svg",
  },

];
export default teamInfo;
