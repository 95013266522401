import React from 'react';
import "./Header.css"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default function Header() {
    return (
        <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark"
                variant="dark" style={{zIndex:3}}>
          <Navbar.Brand href="#landing-section"><img
              src={'/img/gale_logo.svg'} alt={"Greyamp Logo"}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={'ml-auto'}>
              <Nav.Link className={'nav-link-item'} href="#why-gale">Why GALE?</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#features">Features</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#team">Team</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#contact-us">Contact us</Nav.Link>
            </Nav>
            {/*<Link to={"/register"}><Button className={'sign-up-header'} variant="outline-primary">Sign-up</Button></Link>*/}
          </Navbar.Collapse>
        </Navbar>
    )
}
