import React, {Component} from 'react';
import './App.css';
import Header from './components/HeaderSection/Header'
import LandingSection from './components/LandingSection/LandingSection.js'
import WhyGaleSection from "./components/WhyGaleSection/WhyGaleSection";
import TeamSection from './components/TeamSection/TeamSection';
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/FooterSection/Footer";
import FeaturesSection from "./components/FeaturesSection/FeaturesSection";
import Col from "react-bootstrap/Col";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      formData: {
        name: '',
        email: '',
        contactNumber: '',
        subject: '',
        query: ''
      }
    }
  }

  setSubject = ()=>{
    const {formData} = this.state;
    formData.subject='Booking a demo'
    this.setState({
      formData
    })
  }

  onChange = (event) => {
    const {formData} = this.state;

    formData[event.target.name] = event.target.value;

    this.setState({formData});
  };

  render() {
    return (
        <Router>
          <Switch>
            <Route path={'/register'} component={RegistrationForm} />
            <Route path={'/'}>
              <Header style={{position:'absolute',zIndex:1}}/>
              <LandingSection style={{zIndex:1}} setSubject={()=>this.setSubject}/>
              <Col xs={'10'} sm={'6'} md={'5'} lg={'4'} xl={'3'} style={{top:'58.02px',overflow:'hidden'}}
                 className={'gale d-flex'
              + ' justify-content-center sticky-top mx-auto'}>
                <div className={'animate-gale'}>
                <span className={'grow'}><strong>G</strong>row</span>&nbsp; | &nbsp;
                <span className={'adapt'}><strong>A</strong>dapt</span>&nbsp; | &nbsp;
                <span className={'learn'}><strong>L</strong>earn</span>&nbsp; | &nbsp;
                <span className={'engage'}><strong>E</strong>ngage</span>
                </div>
              </Col>
              <WhyGaleSection  style={{zIndex:1}}/>
              <img className={'features-image fill'} src={'img/features.svg'} alt="features_background.svg"/>
              <FeaturesSection style={{zIndex:1}} setSubject={()=>this.setSubject}/>
              <TeamSection style={{zIndex:1}}/>
              <ContactUs style={{zIndex:1}} onChange={()=>this.onChange} formData={this.state.formData}/>
              <Footer style={{zIndex:1}}/>
            </Route>

          </Switch>

        </Router>
    );
  }
}

export default App;
